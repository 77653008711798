<template>
    <div class="d-flex mt-3 justify-content-center">
        <div class="border p-2" v-if="detailPanel && !detailPanel.type">
            <p class="text-center">
                Silahkan pilih slot waktu yang tersedia di panel schedule
            </p>
        </div>
        <div class="border p-2" v-else-if="detailPanel.type !== 'CANCEL'">
            <div class="row">
                <label class="col-6 kt-font-sm mb-0">Spesialisasi</label>
                <label class="col-6 kt-font-sm mb-0 text-right text-danger">
                    <i class="fas fa-times pointer" @click="remove()"></i>
                </label>

                <label class="col-12 kt-font-bolder">
                    <!-- {{
                    event.jadwal.dokter
                        ? event.jadwal.dokter.tujuanRujukan.nama
                        : "-"
                }} -->
                    {{ detailPanel.spesialisasi }}
                </label>
                <label class="col-6 kt-font-sm mb-0">dokter</label>
                <label class="col-12 kt-font-bolder">{{
                    detailPanel.namaDokter
                }}</label>
                <label class="col-12 kt-font-sm mb-0">Jadwal</label>
                <label class="col-12 kt-font-bolder">{{
                    detailPanel.start | formatDate
                }}</label>
                <label class="col-6 kt-font-sm mb-0">Waktu</label>
                <label
                    class="col-6 kt-font-sm mb-0"
                    v-if="detailPanel.type == SlotJadwalType.RESERVED"
                    >Nomor Antrian</label
                >
                <label class="col-6 kt-font-bolder"
                    >{{ detailPanel.jamMulai }} -
                    {{ detailPanel.jamSelesai }}</label
                >
                <label
                    class="col-6 kt-font-bolder"
                    v-if="detailPanel.type == SlotJadwalType.RESERVED"
                    > --- </label
                >
                <!-- <label class="col-6 kt-font-sm mb-0" v-if="isTeleconsult && !isPaid">Telemedicine Link</label> -->
                <!-- <label class="col-6 kt-font-bolder" @click.prevent="setShowModalTempWa(true)" v-if="isTeleconsult && !isPaid"><a href="#">Click Here</a></label> -->
                <div
                    class="col-12 kt-font-sm mb-0 d-flex justify-content-center"
                    v-if="isTeleconsult && !isPaid"
                >
                    <button
                        class="btn btn-brand btn-sm m-2"
                        type="button"
                        @click="setShowModalTempWa(true)"
                    >
                        Payment Link
                    </button>
                </div>
                <div
                    class="col-12 kt-font-sm mb-0 d-flex justify-content-center"
                    v-if="isTeleconsult && isPaid"
                >
                    <button
                        class="btn btn-brand btn-sm m-2"
                        type="button"
                        @click="setShowModalLinktelekonsul(true)"
                    >
                        Link Telekonsul
                    </button>
                </div>
            </div>
            <hr />
            <div class="row" v-if="detailPanel.type == SlotJadwalType.EMPTY">
                <label class="col-12 mb-0">Status: Kosong</label>
                <div class="col-12 text-center my-3">
                    <button
                        v-if="detailPanel.type == SlotJadwalType.EMPTY"
                        class="btn btn-brand btn-sm"
                        type="button"
                        @click="openModal(true)"
                    >
                        Tambah
                    </button>
                </div>
            </div>
            <div class="row" v-else>
                <label class="col-12 mb-0"
                    >Status: {{ detailPanel.type }}
                    <span v-if="detailPanel.type == SlotJadwalType.RESERVED"
                        >#{{ detailPanel.id }}</span
                    >
                </label>

                <!-- data Pasien -->
                <div
                    v-if="detailPanel.type == SlotJadwalType.RESERVED"
                    class="row p-3"
                >
                    <label class="col-6 kt-font-sm mb-0">Nama</label>
                    <label class="col-12 kt-font-bolder">{{
                        detailPanel.namaPasien
                    }}</label>
                    <label class="col-6 kt-font-sm mb-0">Tanggal Lahir</label>
                    <label class="col-6 kt-font-sm mb-0">No. Rekam Medik</label>
                    <label class="col-6 kt-font-bolder">{{
                        detailPanel.tanggalLahir
                    }}</label>
                    <label class="col-6 kt-font-bolder">{{
                        detailPanel.data.nomorMedicalRecord
                    }}</label>
                    <label class="col-6 kt-font-sm mb-0">Jenis Kelamin</label>
                    <label class="col-6 kt-font-sm mb-0">No. Registrasi</label>
                    <label class="col-6 kt-font-bolder">{{
                        $t(detailPanel.gender)
                    }}</label>
                    <label
                        class="col-6 kt-font-bolder"
                        v-if="detailPanel.data.nomorRegistrasi"
                        >{{ detailPanel.data.nomorRegistrasi }}</label
                    >
                    <label class="col-6 kt-font-bolder" v-else>-</label>
                    <label class="col-6 kt-font-sm mb-0">No. Telepon</label>
                    <template v-if="isPermissionWhatsapp">
                        <label
                            class="col-12 kt-font-bolder pointer show-bigger"
                            @click="WAme(detailPanel.telp, detailPanel.generatedId)"
                            >+{{ detailPanel.telp }}</label
                        >
                    </template>
                    <template v-else>
                        <label class="col-12 kt-font-bolder pointer"
                            >+{{ detailPanel.telp }}</label
                        >
                    </template>
                    <label class="col-6 kt-font-sm mb-0">Email</label>
                    <label class="col-12 kt-font-bolder">{{
                        detailPanel.email ? detailPanel.email : "-"
                    }}</label>
                    <label class="col-6 kt-font-sm mb-0">Pesan</label>
                    <label class="col-12 kt-font-bolder">{{
                        detailPanel.pesan ? detailPanel.pesan : "-"
                    }}</label>
                    <label class="col-6 kt-font-sm mb-0">Di Buat Tanggal</label>
                    <label
                        v-b-tooltip.hover.left
                        :title="`Di Buat Oleh: ${detailPanel.createdBy}`"
                        class="col-12 kt-font-bolder"
                        >{{
                            detailPanel.createdDate | formatDateTime
                        }}</label
                    >
                    <label class="col-6 kt-font-sm mb-0">Di Ubah Tanggal</label>
                    <label
                        v-b-tooltip.hover.left
                        :title="
                            `Di Ubah Oleh: ${detailPanel.data.lastModifiedByName}`
                        "
                        class="col-12 kt-font-bolder"
                        >{{
                            detailPanel.lastModifiedDate | formatDateTime
                        }}</label
                    >
                    <label
                        class="kt-font-sm mb-0"
                        :class="isTeleconsult ? 'col-6' : 'col-12'"
                        >Jenis Pembayaran</label
                    >
                    <label class="col-6 kt-font-sm mb-0" v-if="isTeleconsult"
                        >Status Pembayaran</label
                    >
                    <label class="col-6 kt-font-bolder">{{
                        detailPanel.jenisPenjamin
                    }}</label>
                    <label class="col-6 kt-font-bolder" v-if="isTeleconsult">
                        <span v-if="isPaid">Lunas</span>
                        <span v-else>Belum Lunas </span>
                        <i
                            :class="
                                !isLoad ? 'fas fa-sync' : 'fas fa-sync rotating'
                            "
                            style="cursor: pointer;"
                            @click.prevent="refreshPayment(detailPanel.id)"
                        ></i>
                        <span
                            >#{{ detailPanel.teleconsultPayment.id }}</span
                        >
                    </label>
                </div>
                <div
                    v-if="
                        detailPanel.type == SlotJadwalType.TELAT ||
                        detailPanel.type == SlotJadwalType.INTERNAL_EVENT
                    "
                    class="row p-3"
                >
                    <label class="col-6 kt-font-sm mb-0">Remarks :</label>
                    <label class="col-12 kt-font-bolder">{{
                        detailPanel.remark
                    }}</label>
                </div>
                <!-- BUTTON ACTION -->
                <div
                    v-if="detailPanel.type !== 'BLOCKED'
                        && detailPanel.type !== 'ANTRIAN'
                        && detailPanel.type !== SlotJadwalType.IN_OPERATION
                        && detailPanel.type !== SlotJadwalType.CLOSE_REGISTRATION
                        && detailPanel.type !== SlotJadwalType.OTHER_EVENT
                        "
                    class="col-12 text-center my-3"
                >
                    <div class="row justify-content-center">
                        <button
                            class="btn btn-brand btn-sm m-2"
                            v-if="
                                detailPanel.type !== 'RESERVED' &&
                                    detailPanel.type !== 'DEFECT'
                            "
                            type="button"
                            @click="openModalInternal(true)"
                        >
                            {{ this.$i18n.t("button.edit") }}
                        </button>

                        <button
                            class="btn btn-brand btn-sm m-2"
                            v-if="
                                ((detailPanel.type == 'RESERVED' ||
                                    (detailPanel.type == 'DEFECT' &&
                                        detailPanel.type != 'ARRIVED')) && !detailPanel.isArrived)
                            "
                            type="button"
                            @click="openModal(true)"
                        >
                            Update / Reschedule
                        </button>

                        <button
                            class="btn btn-brand btn-sm m-2"
                            v-if="
                                detailPanel.type == 'RESERVED' &&
                                    detailPanel.type == 'ARRIVED' &&
                                    checkPermission(
                                        'ReservasiResource.PUT.Reservasi'
                                    ) 
                            "
                            type="button"
                            @click="cancelArrived()"
                        >
                            Batalkan Kedatangan
                        </button>

                        <button
                            class="btn btn-brand btn-sm m-2"
                            type="button"
                            v-if="
                                detailPanel.type == 'RESERVED' &&
                                    !detailPanel.isArrived &&
                                    detailPanel.jamMulai &&
                                    checkPermission(
                                        'ReservasiResource.PUT.Reservasi.updateStatusArrived'
                                    )
                            "
                            @click="showModalMrReg"
                        >
                            {{ this.$i18n.t("EnumStage.PASIEN_DATANG") }}
                        </button>

                        <button
                            class="btn btn-brand btn-sm m-2"
                            type="button"
                            v-if="
                                checkPermission(
                                    'ReservasiResource.POST.Reservasi.cancelByIds'
                                ) && !detailPanel.isArrived
                            "
                            @click="deleteSlot"
                        >
                            {{ this.$i18n.t("button.cancel") }}
                        </button>

                        <button
                            class="btn btn-brand btn-sm m-2"
                            type="button"
                            v-if="isTeleconsult && !isPaid"
                            @click="recreatePayment(detailPanel.id)"
                        >
                            Recreate Payment Link
                        </button>

                        <div v-if="detailPanel.type == 'RESERVED' && detailPanel.jamMulai">
                            <button
                                v-if="!isLoading"
                                class="btn btn-brand btn-sm m-2"
                                type="button"
                                @click="printReservation"
                            >
                                Cetak Reservasi
                            </button>
                            <button
                                v-else
                                class="btn btn-brand btn-sm m-2"
                                type="button"
                                disabled
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Loading...
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="detailPanel.type == SlotJadwalType.BLOCKED">
                <h6 class="col-12 mb-0">
                    Slot Jadwal telah di block, Silahkan unblock di menu dokter
                </h6>
            </div>
            <div class="row" v-if="detailPanel.type == SlotJadwalType.ANTRIAN">
                <h6 class="col-12 mb-0">
                    Slot Jadwal sudah di klaim dari Caller
                </h6>
            </div>
            <modal-printer
                v-if="showModalPrinter"
                :payload="payloadPrint"
                @print="print"
            ></modal-printer>

            <!-- <div style="font-size: 55px;font-weight: bold; position: absolute;
  clip: rect(0,0,0,0);"  id="printQueue">{{ detailPrint.queueNo }}</div> -->
            <DivToPrint
                :detailPrint="detailPrint"
                ref="detailPrint"
            ></DivToPrint>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import SlotJadwalType from "./../../../../constants/slot-jadwal-type-enum";
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory";
const ReservasiResource = RepositoryFactory.get("reservation");
const SlotJadwalResource = RepositoryFactory.get("SlotJadwalRes");
import SweetAlertTemplate from "./../../../_general/SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
import BlockUI from "./../../../_js/BlockUI";
const blockUI = new BlockUI();
const QueueUserRepository = RepositoryFactory.get("queueUser");
const ServerTimestampRepository = RepositoryFactory.get("serverTimestamp");
import DivToPrint from "../../../_general/DivToPrint.vue";
import qz from "qz-tray";
import { Printd } from "printd";
import CheckPermission from "./../../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
const PrinterResourceRepository = RepositoryFactory.get("printerResource");
export default {
    props: {
        open: {
            type: Function
        }
    },
    data() {
        return {
            SlotJadwalType: SlotJadwalType,
            payloadPrint: {
                printD: false,
                queueId: null
            },
            detailPrint: {
                displayName1: "",
                displayName2: "",
                schedule: "",
                queueNo: "",
                date: "",
                content: "empty"
            },
            link: "https://mandaya.com",
            isLoad: false,
            isPaymentLoad: false,
            output: ""
        };
    },
    components: {
        DivToPrint,
        ModalPrinter: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(() => {
                    resolve(
                        import(/* webpackChunkName: "modal-printer" */ "../../../_select/SelectPrinter.vue")
                    );
                }, 1000);
            })
        })
    },
    watch: {
        events(value) {
            let result;
            if (
                this.event &&
                this.events.length > 0 &&
                this.event.type == "RESERVED"
            ) {
                result = this.events.filter(
                    el =>
                        new Date(el.start).getTime() ==
                            new Date(this.event.start).getTime() &&
                        new Date(el.end).getTime() ==
                            new Date(this.event.end).getTime()
                );
                let jadwal = result[0] ? JSON.parse(result[0].title) : null;
                if (result.length > 0) {
                    var object = {};
                    object.start = result[0].start;
                    object.end = result[0].end;
                    object.jadwal = jadwal.jadwal;
                    object.reservasi = jadwal.reservasi;
                    object.type = jadwal.type;
                    this.setSelectedEvent(object);
                } else {
                    this.setSelectedEvent(null);
                }
            }
        }
    },
    filters: {
        formatDate: function(value) {
            return moment(value).format("dddd, DD MMMM YYYY");
        },
        formatTime: function(value) {
            return moment(value).format("HH:mm");
        },
        formatDateTime: function(value) {
            return moment(value).format("DD MMMM YYYY HH:mm");
        },
        // spesialisName(val) {
        //     let spesialisasiStr = "-";
        //     if (val.dokterSpesialisasis) {
        //         val.dokterSpesialisasis.forEach(item => {
        //             if (item.default) {
        //                 spesialisasiStr = item.spesialisasi.namaIndo;
        //             }
        //         });
        //     }
        //     return spesialisasiStr;
        // }
    },
    computed: {
        ...mapState("DayCalendarStore", {
            event: state => state.selectedEvent,
            events: state => state.events,
            detailPanel: state => state.detailPanel
        }),
        ...mapState("DoctorAppointmentStore", {
            isPermissionWhatsapp: state => state.isPermissionWhatsapp
        }),
        ...mapState("CallerStore", {
            showModalPrinter: state => state.showModalPrinter,
            defaultPrinter: state => state.defaultPrinter,
            isLoading: state => state.isLoading,
            printingOption: state => state.printingOption,
            tenantPrinter: state => state.tenantPrinter
        }),
        status: function(value) {
            if (value == SlotJadwalType.RESERVED) return "Booked";
            else if (value == SlotJadwalType.DEFECT) return "Adjustment";
            else if (value == SlotJadwalType.WAITING) return "Waiting";
        },
        isTeleconsult() {
            return this.detailPanel.isTeleconsult
            // if (
            //     this.event.type == SlotJadwalType.RESERVED &&
            //     this.event.reservasi.teleconsultPayment
            // ) {
            //     return true;
            // } else {
            //     return false;
            // }
        },
        isPaid() {
            return this.detailPanel.isPaid
            // if (!this.event.reservasi.teleconsultPayment) return false;
            // if (
            //     this.event.reservasi.teleconsultPayment.status == "UNDERPAYMENT"
            // ) {
            //     return false;
            // } else {
            //     return true;
            // }
        }
    },
    methods: {
        ...mapMutations({
            setSelectedEvent: "DayCalendarStore/SET_SELECTED_EVENT",
            setShowModalReservasi:
                "DoctorAppointmentStore/SET_SHOW_MODAL_RESERVASI",
            setFromEvent: "DayCalendarStore/SET_FROM_EVENT",
            setShowModalInternal: "DayCalendarStore/SET_SHOW_MODAL_INTERNAL",
            setForUpdate: "DayCalendarStore/SET_FOR_UPDATE",
            setModalWaiting: "DayCalendarStore/SET_SHOW_MODAL_WAITING",
            setShowModalMrReg: "DayCalendarStore/SET_SHOW_MODAL_MRREG",
            setLoading: "CallerStore/SET_LOADING",
            setShowModalPrinter: "CallerStore/SET_SHOW_MODAL_PRINTER",
            setPrintingOption: "CallerStore/SET_PRINTING_OPTION",
            setShowModalTempWa: "DayCalendarStore/SET_SHOW_MODAL_TEMPWA",
            setShowModalLinktelekonsul:
                "DayCalendarStore/SET_SHOW_MODAL_LINKTELEKONSUL",
            setTenantPrinter: "CallerStore/SET_TENANT_PRINTER",
            setPanelInfo: 'DayCalendarStore/SET_PANEL_INFO'
        }),
        ...mapActions({
            RELOAD_CALENDAR: "DoctorAppointmentStore/RELOAD_CALENDAR",
            SOCK_MSG: "DoctorAppointmentStore/SOCK_MSG"
        }),
        defaultSpesialisasi(array) {
            let defaultSpesialisasi = null;
            if (Array.isArray(array)) {
                array.forEach(item => {
                    // console.log(item,'itemmss');
                    if (item.default) {
                        if (item.spesialisasi) {
                            defaultSpesialisasi = spesialisasiMapper(
                                item.spesialisasi
                            );
                        }
                    }
                });
                return defaultSpesialisasi;
            }
        },
        spesialisasiMapper(spesialisasi) {
            if (spesialisasi) {
                return {
                    id: spesialisasi.id,
                    nama: spesialisasi.namaIndo
                };
            }
        },
        remove() {
            // this.setSelectedEvent(null);
            this.setPanelInfo({})
        },
        refreshPayment(reservasiId) {
            if (!this.isPaymentLoad) {
                this.isLoad = true;
                ReservasiResource.refreshPaymentStatus(reservasiId);
                this.isPaymentLoad = true;
                setTimeout(() => {
                    this.isPaymentLoad = false;
                    this.RELOAD_CALENDAR();
                }, 6000);
                setTimeout(() => {
                    toastr.success("Refresh Payment");
                    this.isLoad = false;
                }, 1000);
            }
        },
        showModalMrReg() {
            this.setShowModalMrReg(true);
            // if (new Date().getDate() == new Date(this.event.start).getDate()) {
            //     this.setShowModalMrReg(true);
            // } else {
            //     toastr.error("Tidak sesuai tanggal");
            // }
        },
        deleteSlot() {
            var vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Akan Dihapus.",
                swalType: "info",
                onConfirmButton: function() {
                    vx.delete();
                },
                onCancelButton: function() {
                    blockUI.unblockModal();
                }
            });
        },
        async delete() {
            var vx = this;
            try {
                if (vx.event.type == "RESERVED" || vx.event.type == 'DEFECT' || vx.event.type == 'WAITING') {
                    const generatedId = this.detailPanel.generatedId
                    await ReservasiResource.cancelReservationsV3(generatedId);
                } else {
                    await SlotJadwalResource.deleteInternalEvent(
                        this.event.reservasi.id
                    );
                }
                vx.RELOAD_CALENDAR();
                $("#wlModal").modal("hide");
                this.setModalWaiting(false);
                new SweetAlert().showInfo({
                    swalTitle: "Berhasil!",
                    swalText: "Slot berhasil dihapus.",
                    swalType: "success",
                    onConfirmButton: function() {
                        blockUI.unblockModal();
                    }
                });
            } catch (error) {
                new SweetAlert().showInfo({
                    swalTitle: "Gagal!",
                    swalText: "slot tidak dapat dihapus.",
                    swalType: "error",
                    onConfirmButton: function() {
                        blockUI.unblockModal();
                    }
                });
            }
        },
        openModal(from) {
            if (this.open == undefined) {
                this.setFromEvent(from);
                this.setShowModalReservasi(true);
            } else {
                this.open();
            }
        },
        openModalInternal(from) {
            if (this.open == undefined) {
                this.setFromEvent(from);
                this.setShowModalInternal(true);
                this.setForUpdate(true);
            } else {
                this.open();
            }
        },
        async printReservation() {
            const { data } = await PrinterResourceRepository.getBackOfficeEnv();
            this.setTenantPrinter(data.printingService.tenantPrinter);
            var vx = this;
            vx.setLoading(true);
            vx.payloadPrint.queueId = vx.event.reservasi.queueId;
            if (vx.printingOption == "SERVER") {
                await helpers
                    .checkPrinter()
                    .then(data => {
                        if (data.selectPrinter) {
                            vx.setShowModalPrinter(true);
                        } else {
                            vx.print(vx.payloadPrint);
                        }
                    })
                    .catch(err => {
                        vx.payloadPrint.printD = true;
                        vx.print(vx.payloadPrint);
                    });
            } else {
                vx.payloadPrint.printD = true;
                vx.print(vx.payloadPrint);
            }
        },
        async print({ printD, queueId }) {
            let vx = this;
            if (queueId) {
                await QueueUserRepository.getQueueUser(queueId)
                    .then(async response => {
                        vx.detailPrint.displayName1 =
                            response.data.queueUnit.displayName1;
                        vx.detailPrint.displayName2 =
                            response.data.queueUnit.displayName2;
                        if (response.data.queueUnit.jadwal) {
                            vx.detailPrint.schedule =
                                "(" +
                                helpers.daysToId(
                                    response.data.queueUnit.jadwal.hari
                                ) +
                                ", " +
                                response.data.queueUnit.jadwal.jamMulai +
                                " - " +
                                response.data.queueUnit.jadwal.jamSelesai +
                                ")";
                        }
                        vx.detailPrint.queueNo = response.data.queueNoFormatted;
                        vx.detailPrint.content = `{"content": "${
                            response.data.encryptedId
                        }","type":"antrian"}`;

                        await ServerTimestampRepository.get().then(response => {
                            var dateFormattedId = moment(response.data).format(
                                "dddd, D MMMM YYYY HH:mm:ss"
                            );
                            vx.detailPrint.date = dateFormattedId;
                        });

                        let payload = {
                            printD: printD
                        };
                        this.$refs.detailPrint.printSelectedQueue(payload);
                        //                      const printDiv = document.getElementById('divToPrint')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        cancelArrived() {
            let vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Perubahan Data atau Status akan tersimpan.",
                swalType: "info",
                reverseButtons: true,
                onConfirmButton: function() {
                    // get data reservasi sesuai ID untuk di jadikan DTO payload
                    blockUI.blockModal();
                    vx.submit();
                },
                onCancelButton: function() {
                    blockUI.unblockModal();
                }
            });
        },
        async submit() {
            const response = await ReservasiResource.getReservation(
                this.event.reservasi.id
            );
            if (response.data) {
                let payload = response.data;
                payload.status = "CONFIRM";
                payload.nomorRegistrasi = null; // hapus nomor registrasi
                const { data } = await ReservasiResource.updateReservation(
                    payload
                );
                if (data) {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Perubahan data / status berhasil disimpan.",
                        swalType: "success",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                }
            }
        },
        WAme(numberWA, idReservasi) {
            this.$store.commit("DayCalendarStore/SET_SHOW_MODAL_WA", true);
            this.$store.commit("DayCalendarStore/SET_NUMBER_WA", numberWA);
            this.$store.commit("DayCalendarStore/SET_RESERVASI_ID", idReservasi);
        },
        recreatePayment(reservasiId) {
            ReservasiResource.recreatePaymentLink(reservasiId);
            toastr.success("Payment diperbarui");
            this.RELOAD_CALENDAR();
        },
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        }
    },
    destroyed() {
        this.setShowModalLinktelekonsul(false);
    }
};
</script>

<style lang="scss" scoped></style>
